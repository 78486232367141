import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2e78398"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["disabled", "required", "pattern", "type", "id", "name"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "bg-gray-200 absolute duration-300 origin-0 w-full h-2 top-3 2xl:top-4 left-0"
}
const _hoisted_5 = {
  key: 1,
  class: "focus-within:bg-gray-default absolute duration-300 origin-0 w-full h-2 top-2 left-0"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["outline relative border bg-white border-gray-300 rounded-lg", _ctx.validationClasses()]),
      id: `${_ctx.name}-container`,
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onClick()))
    }, [
      _withDirectives(_createElementVNode("input", {
        onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validate())),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFocus())),
        onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onKeyup())),
        disabled: _ctx.isDisabled,
        required: _ctx.isRequired,
        pattern: _ctx.pattern,
        onKeypress: _cache[3] || (_cache[3] = ($event: any) => {_ctx.preventNonNumericalInput(_ctx.event);}),
        type: _ctx.parsedType,
        id: _ctx.name,
        placeholder: " ",
        name: _ctx.name,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.parsed) = $event)),
        class: "block py-2 2xl:py-3 px-4 w-full xl:text-sm 2xl:text-lg appearance-none focus:outline-none bg-transparent"
      }, null, 40, _hoisted_2), [
        [_vModelDynamic, _ctx.parsed]
      ]),
      _createElementVNode("label", {
        for: _ctx.name,
        class: "absolute top-2 left-0 lg:top-1 2xl:top-0 text-sm 2xl:text-base xl:py-1 2xl:py-3 px-6 -z-1 duration-300 origin-0 text-gray-400"
      }, [
        (_ctx.isDisabled)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.isDisabled)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: "relative title w-full",
          innerHTML: _ctx.formattedPlaceholder
        }, null, 8, _hoisted_6)
      ], 8, _hoisted_3),
      (_ctx.postfix)
        ? _withDirectives((_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "absolute right-0 top-0 py-2 2xl:py-3 px-6 -z-1 title duration-300 origin-0 text-gray-600",
            textContent: _toDisplayString(_ctx.postfix)
          }, null, 8, _hoisted_7)), [
            [_vShow, _ctx.value]
          ])
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "error text-red-600 text-left font-normal text-xs mt-0",
          textContent: _toDisplayString(_ctx.error)
        }, null, 8, _hoisted_8))
      : _createCommentVNode("", true)
  ], 64))
}