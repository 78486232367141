
import { defineComponent } from 'vue';

import Cleave from 'cleave.js';

export default defineComponent({
  inheritAttrs: false,
  name: 'FormInput',
  props: {
    name: String,
    placeholder: String,
    rule: String,
    type: String, /* text, numeric, phone, email, money, float, rib */
    postfix: String,
    value: null,
    error: null,
    default: null,
    isDisabled: Boolean,
    isRequired: Boolean,
    dependencies: null,
    triggerDependencies: Array,
  },
  emits: ['update:value', 'update:error'],
  data() {
    return {
      parsed: null as string|null,
      cleaveValue: {} as Cleave,
    };
  },
  mounted() {
    const vm = this;

    let options: {[k: string]: any} = {};

    if (['money'].includes(this.type as string)) {
      options = {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: ',',
        delimiter: ' ',
        numeralPositiveOnly: true,
      };
    } else if (['integer'].includes(this.type as string)) {
      options = {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: '.',
        delimiter: ' ',
        numeralPositiveOnly: true,
        numeralDecimalScale: 0,
      };
    } else if (['float', 'double', 'numeric'].includes(this.type as string)) {
      options = {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: '.',
        delimiter: ' ',
      };
    } else {
      options = {};
    }

    if (Object.keys(options).length) {
      options.onValueChanged = (e: any) => {
        vm.$emit('update:value', e.target.rawValue);
      };

      this.cleaveValue = new Cleave('#'.concat(this.name as string), options);
    }

    if (this.default !== null && this.default !== undefined) this.$emit('update:value', this.default);
  },
  methods: {
    onFocus(event: any) {
      if (this.type === 'phone') this.initFieldValue('0');
      else if (this.type === 'rib') this.initFieldValue('001');
    },
    onKeyup(event: any) {
      if (['phone', 'fax'].includes(this.type as string)) this.parsePhoneNumber();
      else if (this.type === 'rib') this.parseRib();
    },
    onClick(event: any) {
      if (this.isDisabled) this.validate();
    },
    validate() {
      const rules = this.rule!.split('|');
      const vm = this;

      // Validate dependencies
      const dependencies = this.triggerDependencies === undefined ? [] : this?.triggerDependencies;

      Object.keys(dependencies).forEach((key: any) => {
        const object = document.getElementById(`${dependencies[key]}-container`);
        if (object) {
          object.click();
        }
      });

      rules.every((element: string) => {
        if (element === 'required' && !this.isDisabled) {
          if (vm.value === null || vm.value === '') {
            vm.$emit('update:error', 'Ce champs est requis.');
            return false;
          }
        } else if (element === 'numeric') {
          if (vm.value && Number.isNaN(vm.value)) {
            vm.$emit('update:error', 'Ce champs doit être de type numérique.');
            return false;
          }
        } else if (element.startsWith('min:')) {
          const min = element.substr(4);

          if (vm.value && !Number.isNaN(min) && parseFloat(vm.value) < parseFloat(min)) {
            vm.$emit('update:error', `Ce champs doit être supérieur ou égale à ${min}.`);
            return false;
          }
        } else if (element.startsWith('max:')) {
          const max = element.substr(4);

          if (vm.value && !Number.isNaN(max) && parseFloat(vm.value) > parseFloat(max)) {
            vm.$emit('update:error', `Ce champs doit être inférieur ou égale à ${max}.`);
            return false;
          }
        } else if (element === 'regex_alpha') {
          if (vm.value && !/^[\p{L} ']+$/u.test(vm.value)) {
            vm.$emit('update:error', 'Ce champs doit être de type alphabetique.');
            return false;
          }
        } else if (element === 'regex_alpha_coma') {
          if (vm.value && !/^[\p{L} ,']+$/u.test(vm.value)) {
            vm.$emit('update:error', 'Ce champs doit être de type alphabetique.');
            return false;
          }
        } else if (element.startsWith('phone:')) {
          const types = element.substr(6).split(',');

          if (vm.value) {
            let correct = true;

            if (types.includes('local') && types.includes('mobile')) {
              if (!/^0[1,2,3,4][0-9]{1}[0-9]{2}[0-9]{2}[0-9]{2}$/.test(vm.value) && !/^0[5,6,7][0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$/.test(vm.value)) {
                vm.$emit('update:error', 'Ce champs doit correspondre à un numéro de téléphone fixe ou mobile.');
                correct = false;
              }
            } else if (types.includes('mobile') && !/^0[5,6,7][0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$/.test(vm.value)) {
              vm.$emit('update:error', 'Ce champs doit correspondre à un numéro de téléphone mobile.');
              correct = false;
            } else if (types.includes('local') && !/^0[1,2,3,4][0-9]{1}[0-9]{2}[0-9]{2}[0-9]{2}$/.test(vm.value)) {
              vm.$emit('update:error', 'Ce champs doit correspondre à un numéro de téléphone fixe.');
              correct = false;
            }

            return correct;
          }
        } else if (element === 'email') {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (vm.value && !re.test(String(vm.value).toLowerCase())) {
            vm.$emit('update:error', 'Ce champs doit correspondre à un email.');
            return false;
          }
        } else if (element.startsWith('rib:')) {
          if (vm.value) {
            const bankCode = element.substr(4);

            let wrong = false;

            if (vm.value.length !== 20) {
              wrong = true;
            } else {
              const bank = vm.value.substr(0, 3);
              const agency: number = parseInt(vm.value.substr(3, 5), 10);
              const account: number = parseInt(vm.value.substr(8, 10), 10);
              const key: number = parseInt(vm.value.substr(18, 2), 10);

              const realKey = 97 - ((50 * (agency % 97) + 3 * (account % 97)) % 97);

              if (bank !== bankCode || realKey !== key) wrong = true;
            }

            if (wrong) {
              vm.$emit('update:error', 'Ce champs doit corréspondre à un RIB valide.');
              return false;
            }
          }
        } else if (element.startsWith('duration_rule:')) {
          const dependency = element.substr(14, element.indexOf(',') - element.indexOf(':') - 1);
          const vals = element.substr(14 + dependency.length + 1)!.split(',');

          let min = null;
          let max = null;

          if (vm.value && vm.dependencies && vm.dependencies[dependency] !== null) {
            let minRule = parseFloat(vals[1]);
            let maxRule = parseFloat(vals[2]);

            const left = (parseFloat(vals[0]) - vm.dependencies[dependency]) * 12;

            if (left < maxRule) {
              maxRule = left;
            }

            if (left < minRule) {
              minRule = left;
            }

            if (parseFloat(vm.value) > maxRule || parseFloat(vm.value) < minRule) {
              min = minRule;
              max = maxRule;
            }

            if (min !== null && max !== null) {
              if (min === max) {
                vm.$emit('update:error', `${this.placeholder} doit être de ${min} ${this.postfix}.`);
              } else {
                vm.$emit('update:error', `${this.placeholder} doit être compris(e) entre ${min} et ${max} ${this.postfix}.`);
              }
              return false;
            }
          }
        } else if (element.startsWith('required_if:')) {
          const dependency = element.substr(12, element.indexOf(',') - element.indexOf(':') - 1);
          const value = element.substr(element.lastIndexOf(',') + 1);

          if (vm.dependencies[dependency] === value && vm.value === null) {
            vm.$emit('update:error', 'Ce champs est requis.');
            return false;
          }
        } else if (element.startsWith('same:')) {
          const dependency = element.substr(5);

          if (vm.value && vm.dependencies[dependency] && vm.value !== vm.dependencies[dependency]) {
            vm.$emit('update:error', `Ce champs doit comporter la même valeur que celle de ${dependency}.`);
            return false;
          }
        }

        // If no error, clean old one
        vm.$emit('update:error', null);

        return true;
      });
    },
    parsePhoneNumber() {
      const cleaned = this.value.replace(/\D/g, '');

      /* if (event.keyCode === 8) cleaned = cleaned.slice(0, -1); */

      if (cleaned.startsWith('01') || cleaned.startsWith('02') || cleaned.startsWith('03') || cleaned.startsWith('04')) {
        if (cleaned.length >= 8) this.parsed = `${cleaned.substr(0, 3)} ${cleaned.substr(3, 2)} ${cleaned.substr(5, 2)} ${cleaned.substr(7, 2)}`;
        else if (cleaned.length >= 6) this.parsed = `${cleaned.substr(0, 3)} ${cleaned.substr(3, 2)} ${cleaned.substr(5, 2)}`;
        else if (cleaned.length >= 4) this.parsed = `${cleaned.substr(0, 3)} ${cleaned.substr(3, 2)}`;
        else this.parsed = `${cleaned}`;
      } else if (cleaned.startsWith('05') || cleaned.startsWith('06') || cleaned.startsWith('07')) {
        if (cleaned.length >= 9) this.parsed = `${cleaned.substr(0, 4)} ${cleaned.substr(4, 2)} ${cleaned.substr(6, 2)} ${cleaned.substr(8, 2)}`;
        else if (cleaned.length >= 7) this.parsed = `${cleaned.substr(0, 4)} ${cleaned.substr(4, 2)} ${cleaned.substr(6, 2)}`;
        else if (cleaned.length >= 5) this.parsed = `${cleaned.substr(0, 4)} ${cleaned.substr(4, 2)}`;
        else this.parsed = `${cleaned}`;
      } else {
        this.parsed = `${cleaned}`;
      }
    },
    parseRib() {
      const cleaned = this.value.replace(/\D/g, '');

      /* if (event.keyCode === 8) cleaned = cleaned.slice(0, -1); */

      if (cleaned.length >= 19) this.parsed = `${cleaned.substr(0, 3)} ${cleaned.substr(3, 5)} ${cleaned.substr(8, 10)} ${cleaned.substr(18, 2)}`;
      else if (cleaned.length >= 9) this.parsed = `${cleaned.substr(0, 3)} ${cleaned.substr(3, 5)} ${cleaned.substr(8, 10)}`;
      else if (cleaned.length >= 4) this.parsed = `${cleaned.substr(0, 3)} ${cleaned.substr(3, 5)}`;
      else this.parsed = `${cleaned}`;
    },
    initFieldValue(value: string) {
      if (!this.value) this.parsed = value;
    },
    validationClasses() {
      let classes = '';

      if (this.isDisabled) classes = classes.concat('disabled');

      if (this.error) classes = classes.concat(' invalidated-input');
      else if (this.value && !this.isDisabled) classes = classes.concat(' validated-input');
      else classes = classes.concat(' focus-within:border-primary');

      return classes;
    },
    preventNonNumericalInput(event: any) { /* Firefox fix */
      if (this.parsedType === 'numeric') {
        const e = event || window.event;
        const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9,]+$/)) {
          e.preventDefault();
        }
      }
    },
  },
  watch: {
    value(value, oldValue) {
      if (Object.keys(this.cleaveValue).length) {
        if (this.cleaveValue.properties.result === '') {
          this.parsed = new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(value);
        } else if (value === null) {
          this.parsed = '';
        } else {
          this.parsed = this.cleaveValue.properties.result;
        }
      } else this.parsed = this.value;
    },
    parsed(value, oldValue) {
      if (!Object.keys(this.cleaveValue).length) {
        let tmp = value;

        if (['phone', 'rib', 'fax'].includes(this.type as string)) tmp = value.replace(/\D/g, '');

        this.$emit('update:value', tmp);
      }
    },
    default() {
      if (this.default !== null && this.default !== undefined) this.$emit('update:value', this.default);
    },
  },
  computed: {
    parsedType(): string {
      if (['money', 'numeric', 'float'].includes(this.type as string)) return 'numeric';
      if (['email'].includes(this.type as string)) return 'email';
      if (['password'].includes(this.type as string)) return 'password';
      if (['date'].includes(this.type as string)) return 'date';

      return 'text';
    },
    formattedPlaceholder(): string {
      return `${this.placeholder} ${this.isRequired && !this.isDisabled ? '<span class="text-red-500">*</span>' : ''}`;
    },
    pattern(): string {
      if (['money', 'numeric', 'float'].includes(this.type as string)) return '[0-9,]*';

      return '';
    },
  },
});
